import React from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import { graphql, navigate } from "gatsby";
import styled from "styled-components";
import path from "path";
import { KeyboardArrowLeft } from "styled-icons/material";

import BlogLayout from "../components/BlogLayout";
import Image from "../components/Image/BlogImage";
import { H2, H3, Span } from "../components/Typography";
import Pagination from "../components/Pagination";
import SEO from "../components/SEO";
import CustomLink from "../components/Link";

import SortByDate from "../utils/sortByDate";
import slugify from "../utils/slugify";
import colors from "../utils/theme/colors";

const WrapperContent = styled(Flex)`
  flex-direction: column;
  .gatsby-resp-image-wrapper {
    white-space: nowrap;
    margin: 15px auto;
    .gatsby-resp-image-image {
      box-shadow: none !important;
    }
  }
  h3 {
    font-size: 20px;
    font-weight: 900;
  }
  h4 {
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  strong {
    font-weight: 700;
  }
  ul {
    margin-left: 40px;
    display: table;
    white-space: normal;
  }
  p {
    img {
      display: block;
      margin: 0 auto;
    }
  }
  p,
  h1,
  h3,
  h2 {
    margin-bottom: 30px;
  }
  ol {
    display: table;
  }
  ol > li {
    margin-bottom: 5px;
  }
  a {
    text-decoration: none;
    color: red;
  }
  pre {
    padding: 15px 10px;
    margin: 0 0 25px;
    font-size: 13px;
    line-height: 1.4;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
  }
  h1,
  h2 {
    font-size: 28px;
    font-weight: 900;
  }
  blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 14px;
    border-left: 5px solid #ccc;
    p {
      margin-bottom: 0;
    }
  }
  hr {
    display: block;
    width: 100%;
    unicode-bidi: isolate;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    overflow: hidden;
    border-style: inset;
    border-width: 1px;
  }
  li > p,
  ul {
    margin-bottom: 30px;
  }
  img {
    width: 100%;
  }
`;

const BlogPostTemplate = ({
  data: {
    blogPageInfo: {
      frontmatter: { longTitle }
    },
    bgMobile: {
      childImageSharp: { fluid: bgMobileFluid }
    },
    blog: {
      html,
      fileAbsolutePath,
      fields: { slug },
      frontmatter: {
        title,
        postImage: {
          childImageSharp: { fluid: postImage }
        },
        keywords,
        introtext,
        date,
        author
      }
    },
    allBlogs: { edges: allBlogs },
    site: {
      siteMetadata: { siteUrl, titleTemplate, socialLinks }
    }
  }
}) => {
  const sortBlog = SortByDate(allBlogs);
  const cuurentBlog = sortBlog.findIndex(
    ({ node }) => node.fileAbsolutePath === fileAbsolutePath
  );
  return (
    <>
      <SEO
        titleTemplate={titleTemplate}
        title={title}
        keywords={keywords}
        url={`${siteUrl}${slug}`}
        description={introtext}
        image={`${siteUrl}${postImage.src}`}
        pageType="article"
      />
      <BlogLayout
        title="blog"
        socialTitle={title}
        allBlogs={allBlogs}
        socialLinks={socialLinks}
        slug={`${siteUrl}${slug}`}
        bgMobileFluid={bgMobileFluid}
        longTitle={longTitle}
      >
        <Flex
          width="100%"
          ml={[0, 0, "44px", "44px"]}
          mr={[0, 2, "44px", "44px"]}
          flexDirection="column"
          css={{ position: "relative" }}
        >
          <CustomLink
            color={colors.secondary2}
            to="/blog"
            css={`
              display: flex;
              align-items: center;
              position: absolute;
              top: -50px;
              &:hover {
                svg {
                  fill: ${colors.primary1};
                }
                color: ${colors.primary1};
              }
              svg {
                margin-left: -8px;
              }
              @media (max-width: 1280px) {
                svg {
                  width: 27px;
                  height: 27px;
                  margin-left: -4px;
                }
                h3 {
                  line-height: 26px;
                }
              }
              @media (max-width: 768px) {
                svg {
                  width: 23px;
                  height: 23px;
                  margin-left: -4px;
                }
                h3 {
                  line-height: 22px;
                }
              }
            `}
          >
            <KeyboardArrowLeft size={30} fill={colors.secondary2} />
            <H3>back to blog</H3>
          </CustomLink>
          <Image fluid={postImage} fadeIn mb={5} />
          <Flex alignItems="space-between" marginBottom="30px" fontSize="17px">
            <Span>{new Date(date).toDateString().slice(4)}</Span>
            <Span mr="3px" color={colors.secondary2}>
              {" "}
              | by
            </Span>
            <Span color={colors.primary1}>{author}</Span>
          </Flex>
          <H2 mb={2} style={{ textTransform: "none" }}>
            {title}
          </H2>
          <WrapperContent
            id="___gatsby"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <Pagination
            page={cuurentBlog}
            nextButtonClick={() => {
              navigate(
                `/blog/${slugify(
                  sortBlog[cuurentBlog - 1].node.frontmatter.category
                )}/${path.basename(
                  sortBlog[cuurentBlog - 1].node.fileAbsolutePath,
                  ".md"
                )}`
              );
            }}
            previousButtonClick={() => {
              navigate(
                `/blog/${slugify(
                  sortBlog[cuurentBlog + 1].node.frontmatter.category
                )}/${path.basename(
                  sortBlog[cuurentBlog + 1].node.fileAbsolutePath,
                  ".md"
                )}`
              );
            }}
            countPage={Math.ceil(allBlogs.length - 1)}
          />
        </Flex>
      </BlogLayout>
    </>
  );
};

BlogPostTemplate.propTypes = {
  data: PropTypes.shape().isRequired
};

export default BlogPostTemplate;

export const query = graphql`
  query blogPost($id: String) {
    blogPageInfo: markdownRemark(frontmatter: { name: { eq: "blog" } }) {
      frontmatter {
        longTitle
      }
    }
    bgMobile: file(relativePath: { eq: "images/bg-mobile.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    blog: markdownRemark(id: { eq: $id }) {
      id
      fileAbsolutePath
      frontmatter {
        category
        postImage {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        date
        title
        introtext
        author
        keywords
      }
      fields {
        slug
      }
      html
    }
    allBlogs: allMarkdownRemark(
      filter: { frontmatter: { collection_name: { eq: "blog-posts" } } }
    ) {
      edges {
        node {
          id
          fileAbsolutePath
          frontmatter {
            category
            date
            title
            tags
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        titleTemplate
        socialLinks {
          icon
          url
        }
      }
    }
  }
`;
